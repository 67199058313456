.home-download {
  padding: 75px 0;
  overflow: hidden;
  @include r(767) {
    padding: 40px 0; }
  &__content {
    @include center;
    @include r(767) {
      flex-direction: column; } }
  &__img {
    margin-right: 50px;
    @include r(767) {
      margin-right: 0;
      margin-bottom: 25px; }
    img {
      max-width: 100%; } }
  &__link {
    a {
      @include center-column;
      i {
        color: $strawberry;
        font-size: 30px;
        margin-bottom: 20px; }
      span {
        @include tr;
        padding: 11px 12px; } } } }
