.home-resources {
  padding: 105px 0;
  border-bottom: 1px solid $very-light-pink;
  overflow: hidden;
  @include r(767) {
    padding: 45px 0;
    border-bottom: none; }
  &__content {
    padding: 0 50px;
    @include r(767) {
      padding: 0; } }
  &__column {
    margin: 0 15px;
    @include r(767) {
      border-bottom: 2px solid $light-grey-blue; }
    h2 {
      margin-bottom: 75px;
      @include r(767) {
        margin-bottom: 30px; } }
    &__img {
      height: 155px;
      @include cover;
      display: flex;
      align-items: center;
      padding-left: 30px;
      @include r(767) {
        display: none; }
      &-1 {
        background-image: url('../img/home/resources-1.png');
        margin-bottom: 60px;
        @include r(767); }

      &-2 {
        background-image: url('../img/home/resources-2.png');
        margin-top: 60px; }
      .btn {
        border: solid 2px $white;
        color: $white;
        &:hover {
          background: $white;
          color: rgba(0, 0, 0, 0.8); } } }
    &__text {
      display: flex;
      justify-content: flex-end;
      @include r(767) {
        flex-direction: column;
        align-items: center; }
      li {
        font-size: 16px;
        font-weight: normal;
        color: $black;
        text-align: right;
        padding: 15px 0 25px;
        border-bottom: 1px solid $very-light-pink;
        padding-left: 30%;
        @include r(767) {
          text-align: left;
          padding-left: 0;
          padding-right: 0;
          font-size: 20px; }
        &:last-child {
          border-bottom: none; }
        span {
          font-size: 14px;
          margin-top: 10px;
          display: block;
          @include r(767) {
            font-size: 18px; } } }
      a {
        font-size: 16px;
        font-weight: 600;
        color: $strawberry;
        display: inline-block;
        line-height: 1;
        border-bottom: 1px solid $strawberry;
        margin-bottom: 45px;
        margin-top: 15px;
        @include r(767) {
          font-size: 20px; } } }
    &--alerts {
      @include r(767) {
        padding-top: 45px; }
      li {
        padding-left: 0;
        padding-right: 30%;
        text-align: left; } } } }
