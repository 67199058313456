.icon-arrow-down {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-arrow-left {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-arrow-right {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-download {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-email-open {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-fb-2 {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-in-2 {
  width: 1em;
  height: 1em;
  fill: #000;
}
.icon-phone {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-twitter {
  width: 1em;
  height: 1em;
  fill: initial;
}
