.newsletters {
  &__content {
    padding: 15px 0 50px; }
  &__block {
    border-bottom: 1px solid $very-light-pink;
    padding: 35px 0;
    &:last-child {
      border-bottom: none; }
    h4 {
      font-weight: 600;
      color: $charcoal-grey;
      font-size: 16px;
      margin-bottom: 12px;
      text-transform: uppercase;
      @include r(767) {
        font-size: 20px; } }
    p {
      font-weight: normal;
      color: $black;
      text-align: justify; }
    a {
      font-size: 14px;
      line-height: 2;
      color: #ff0c11;
      text-decoration: underline;
      @include r(767) {
        font-size: 18px;
        display: block; } } }
  .page__nav {
    margin-top: 15px; } }
