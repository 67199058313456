.home-news {
  background-color: #f9f9f9;
  padding: 75px 0;
  overflow: hidden;
  @include r(767) {
    padding: 40px 0; }
  h2 {
    margin-bottom: 55px;
    @include r(767) {
      margin-bottom: 30px; } }
  &__block {
    margin: 0 12px 50px;
    display: block;
    @include r(767) {
      margin-bottom: 27px; }
    &__img {
      @include cover;
      height: 115px;
      position: relative;
      &:before {
        content: '';
        background: $cherry-red;
        width: 100%;
        height: 5px;
        display: block;
        position: absolute;
        bottom: -5px;
        left: 0; }
      &:after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        border: 70px solid transparent;
        border-top: 8px solid $cherry-red;
        bottom: -82px; } }
    p {
      margin-top: 35px;
      color: $black; }
    span {
      font-size: 14px;
      margin-top: 10px;
      color: $black;
      display: block;
      @include r(767) {
        font-size: 18px; } } }
  .btn {
    padding: 11px 35px; } }
