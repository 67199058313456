.careers {
  h2 {
    font-size: 28px;
    margin-bottom: 40px;
    @include r(767) {
      font-size: 26px; } }
  &__form {
    @include center-column;
    max-width: 570px;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    input, textarea {
      width: 100%;
      margin-bottom: 20px; }
    textarea {
      height: 90px; }
    input[type="submit"] {
      width: 150px;
      height: 40px; } } }
