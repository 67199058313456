html {}
body {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  position: relative;
  font-size: 16px;
  line-height: 1.38;
  color: $charcoal-grey;
  @include r(767) {
    font-size: 20px; } }
.container {
  width: 100% !important;
  max-width: 1135px;
  box-sizing: border-box;
  @include r(1150) {
    padding: 0 15px; } }
a {
  text-decoration: none;
  transition: all 0.3s; }
input[type="submit"] {
  appearance: none;
  -webkit-appearance: none;
  background: none; }
textarea {
  resize: none; }
h2 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  color: $gunmetal;
  text-transform: uppercase; }
h3 {
  font-size: 19px;
  text-transform: uppercase;
  font-weight: 600;
  color: $gunmetal; }
input, textarea {
  border: none;
  box-shadow: none;
  border-bottom: 1px solid $very-light-pink-three;
  padding: 15px;
  font-size: 16px;
  color: $charcoal-grey;
  @include placeholder-color($light-grey-blue);
  @include r(767) {
    font-size: 20px; }
  &:focus {
    border-color: $gunmetal; }
  &:invalid {
    color: $cherry-red;
    border-color: $cherry-red; } }
input[type="number"] {
  &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    margin: 0; } }


@include r(767) {
  .hidden-xs-1 {
    display: none; } }
@include r(767) {
	.hidden-xs {
		display: none !important; } }
@media only screen and (min-width: 768px) and (max-width: 1040px) {
	.hidden-sm {
		display: none !important; } }
@include rmin(1041) {
	.hidden-md {
		display: none !important; } }
.hidden {
  display: none; }


// swiper
.swiper-button-prev, .swiper-button-next {
  background: none;
  width: 58px;
  height: 58px;
  background-color: $gunmetal;
  @include center;
  z-index: 70;
  @include r(767) {
    display: none; }
  i {
    color: $white;
    font-size: 35px;
    position: relative; } }
.swiper-button-prev {
  left: 0;
  i {
    left: -3px; } }
.swiper-button-next {
  right: 0;
  i {
    right: -3px; } }
.swiper-button-disabled {
  opacity: 1 !important; }
.swiper-pagination-bullet {
  width: 14px;
  height: 14px;
  background-color: $light-grey-blue;
  opacity: 1;
  margin: 0 13px !important; }
.swiper-pagination-bullet-active {
  background: $gunmetal; }
.swiper-pagination {
  bottom: 40px !important; }







//btn
.btn {
  @include center;
  display: inline-flex;
  padding: 8px 26px;
  border: solid 1px $cherry-red;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: $strawberry;
  @include tr;
  @include r(767) {
    font-size: 20px; }
  &:hover {
    background: $cherry-red;
    color: $white; } }


.breadcrumbs {
  @include center-y;
  position: relative;
  z-index: 2;
  flex-wrap: wrap;
  a {
    font-size: 14px;
    font-weight: normal;
    color: #f43524;
    position: relative;
    margin-bottom: 5px;
    @include r(767) {
      font-size: 18px; }
    &:hover {
      &:before {
        opacity: 1; } }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 1px;
      width: calc(100% - 25px);
      background: #f43524;
      opacity: 0;
      @include tr; }
    &:after {
      content: $icon-arrow-right;
      font-family: "iconfont";
      font-size: 8px;
      margin: 0 9px;
      color: #3c4646; }
    &:last-child {
      color: #3c4646;
      cursor: default;
      &:after, &:before {
        display: none; } } } }









.pages {
  @include center-column;
  padding: 50px 0;
  h1 {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    color: $gunmetal;
    margin-bottom: 40px; }
  ul {
    @include center-column; }
  li {
    margin-bottom: 15px; }
  a {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: $strawberry;
    &:hover {
      text-decoration: underline; } } }
