@import "corporate";
@import "firm";
@import "alerts";
@import "newsletters";
@import "team";
@import "careers";

.page {
  @include r(767) {
    padding-top: 100px; }
  &__intro {
    padding: 18px 0 65px;
    @include cover;
    background-position: 0 0;
    position: relative;
    @include r(767) {
      padding: 40px 0 30px; }
    &:before {
      content: '';
      background: rgba($white, 0.4);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }
    h1 {
      position: relative;
      z-index: 2;
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      color: $charcoal-grey;
      margin-top: 20px;
      border-bottom: 2px solid $cherry-red;
      display: inline-block;
      position: relative;
      left: 50%;
      text-transform: uppercase;
      transform: translate(-50%);
      @include r(767) {
        font-size: 30px;
        transform: none;
        left: 0;
        margin-bottom: 15px;
        margin-top: 0; } }
    &__content {
      @include r(767) {
        display: flex;
        flex-direction: column-reverse;
        align-items: center; } } }
  &__text {
    padding: 50px 0;
    @include r(767) {
      padding: 30px 0; }
    p {
      font-weight: normal;
      line-height: 1.38;
      text-align: justify;
      color: $black;
      margin-bottom: 30px;
      @include r(767) {
        text-align: justify; }
      a {
        color: #ff3a3f;
        text-decoration: underline; } } }
  &__nav {
    @include center;
    margin-top: 50px;
    a {
      padding: 5px 8px;
      font-size: 16px;
      font-weight: normal;
      color: $gunmetal;
      display: block;
      @include r(767) {
        font-size: 20px; }
      &:hover {
        text-decoration: underline; } }
    li {
      &.active {
        a {
          color: #ff2628;
          cursor: default;
          &:hover {
            text-decoration: none; } } } } } }
