@font-face {
  font-family: "iconfont";
  src: url('../fonts/iconfont.eot');
  src: url('../fonts/iconfont.eot?#iefix') format('eot'),
    url('../fonts/iconfont.woff2') format('woff2'),
    url('../fonts/iconfont.woff') format('woff'),
    url('../fonts/iconfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

$icon-arrow-down: "\EA01";
$icon-arrow-left: "\EA02";
$icon-arrow-right: "\EA03";
$icon-close: "\EA04";
$icon-download: "\EA05";
$icon-email-open: "\EA06";
$icon-fb-2: "\EA07";
$icon-in-2: "\EA08";
$icon-phone: "\EA09";
$icon-search: "\EA0A";
$icon-twitter: "\EA0B";


%icon {
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  @extend %icon;
  display: inline-block;
}

.icon-arrow-down:before { content: $icon-arrow-down; }
.icon-arrow-left:before { content: $icon-arrow-left; }
.icon-arrow-right:before { content: $icon-arrow-right; }
.icon-close:before { content: $icon-close; }
.icon-download:before { content: $icon-download; }
.icon-email-open:before { content: $icon-email-open; }
.icon-fb-2:before { content: $icon-fb-2; }
.icon-in-2:before { content: $icon-in-2; }
.icon-phone:before { content: $icon-phone; }
.icon-search:before { content: $icon-search; }
.icon-twitter:before { content: $icon-twitter; }
