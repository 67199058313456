.home-firm {
  padding: 110px 0;
  overflow: hidden;
  @include r(767) {
    padding: 60px 0 50px; }
  &__text {
    h2 {
      margin-bottom: 50px;
      @include r(767) {
        margin-bottom: 30px; } }
    p {
      text-align: center;
      margin-bottom: 65px;
      @include r(767) {
        margin-bottom: 35px;
        text-align: justify;
        font-size: 21px; } } }
  &__list {
    display: flex;
    justify-content: center;
    @include r(767) {
      align-items: flex-end; }
    &__img {
      display: flex;
      align-items: flex-end;
      margin: 60px 32px 0;
      @include r(767) {
        margin: 0 18px; }
      img {
        @include r(767) {
          height: 100%; } }
      &:first-child {
        height: 51px; }
      &:nth-child(2) {
        height: 57px; }
      &:nth-child(3) {
        height: 27px; }
      &:last-child {
        height: 55px; } } } }
