.intro {
  height: 500px;
  overflow: hidden;
  @include r(767) {
    height: 600px;
    margin-top: 100px; }
  .swiper-intro, .container {
    height: 100%; }
  .swiper-slide {
    @include cover; }
  &__text {
    height: 100%;
    @include center-column;
    h2 {
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      color: $gunmetal;
      max-width: 875px;
      margin-bottom: 23px;
      text-transform: uppercase;
      @include r(767) {
        padding: 0 30px; } }
    p {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: $gunmetal;
      margin-bottom: 45px;
      @include r(767) {
        display: none; } } } }
