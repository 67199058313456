$black: #000000;
$gunmetal: #5a6464;
$charcoal-grey: #373d3d;
$cherry-red: #ff191b;
$very-light-pink: #efefef;
$light-grey-blue: #aeb8b8;
$very-light-pink-two: #e7e7e7;
$strawberry: #ff2c2e;
$white: #ffffff;
$very-light-pink-three: #dadada;
