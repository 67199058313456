.footer {
  border-top: 2px solid $cherry-red;
  padding: 55px 0 70px;
  @include r(767) {
    padding: 50px 0; }
  h3 {
    margin-bottom: 35px;
    @include r(767) {
      font-size: 30px;
      font-weight: 600;
      text-align: center; } }
  &__column {
    padding: 0 40px;
    @include r(767) {
      padding:  0 30px; }
    input {
      margin-bottom: 10px;
      width: 100%; }
    textarea {
      width: 100%;
      height: 100px;
      margin-bottom: 10px; }
    button {
      background: none;
      width: 150px;
      height: 40px; }
    p {
      font-weight: normal;
      line-height: 1.38;
      color: $gunmetal;
      margin-bottom: 25px; } }
  &__form {
    padding-left: 0; }
  &__contacts {
    border-right: 1px solid $very-light-pink-three;
    border-left: 1px solid $very-light-pink-three;
    @include r(767) {
      border: none; }
    .btn {
      @include r(767) {
        position: relative;
        left: 50%;
        transform: translate(-50%);
        margin-top: 10px;
        height: 44px; } }
    &__row {
      display: flex;
      margin-bottom: 20px;
      @include r(767) {
        &:last-child {
          justify-content: center;
          span {
            display: none; } } }
      span {
        display: block;
        width: 70px;
        font-size: 16px;
        font-weight: normal;
        color: $cherry-red;
        margin-right: 17px;
        text-align: right;
        @include r(767) {
          font-size: 20px;
          width: 90px; } }
      a, p {
        font-size: 16px;
        font-weight: normal;
        line-height: 1.25;
        color: $gunmetal;
        flex: 1;
        @include r(767) {
          font-size: 20px; } }
      a:hover {
        text-decoration: underline; } }
    &__social {
      display: flex;
      margin-top: 20px;
      @include r(767) {
        margin-top: 30px; }
      a {
        margin-right: 15px;
        color: $gunmetal;
        font-size: 23px;
        @include r(767) {
          font-size: 30px; }
        &:hover {
          text-decoration: none;
          color: $cherry-red; } } } }
  &__mailing {
    padding-right: 0; } }
