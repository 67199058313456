.corporate {
  .swiper-button-prev, .swiper-button-next {
    @include r(1500) {
      width: 40px;
      height: 40px;
      i {
        font-size: 20px; } }
    @include r(1040) {
      display: flex; } }
  h2 {
    margin-bottom: 45px; }
  &__block {
    width: 100%;
    height: 175px;
    display: block;
    position: relative;
    img {
      height: 100%;
      width: 100%;
      @include object-fit(cover); }
    span {
      width: 100%;
      height: 100%;
      @include center;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0 ,0, 0.5);
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: $white;
      text-transform: uppercase;
      padding: 0 70px;
      z-index: 3;
      box-sizing: border-box;
      @include tr;
      @include r(1500) {
        font-size: 17px; }
      &:hover {
        background: rgba(180, 100, 120, 0.7) !important; }
      @include r(1500) {
        padding: 0 40px; }
      @include r(767) {
        padding: 0 60px; } }
    &:hover {
      span {
        background: rgba(0, 0 ,0, 0.5); } } } }
