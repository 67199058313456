.header {
  padding: 12px 0;
  background: $white;
  position: relative;
  z-index: 10;
  @include r(1040) {
    padding: 35px 0;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    box-shadow: 5px 5px 15px 0 rgba(31, 31, 31, 0.07); }
  .menu-open {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate(0, -50%);
    width: 30px;
    height: 30px;
    display: none;
    z-index: 99;
    @include r(1040) {
      display: flex; }
    span {
      width: 30px;
      height: 3px;
      margin-top: 13px;
      background: #72787d;
      display: block;
      border-radius: 2px;
      &:before, &:after {
        content: '';
        width: 30px;
        height: 3px;
        background: #72787d;
        position: absolute;
        left: 0;
        border-radius: 2px; }
      &:before {
        top: 3px; }
      &:after {
        bottom: 4px; } } }
  &__content {
    @include center-y;
    justify-content: space-between; }
  &__logo {
    @include r(1040) {
      margin-left: 60px;
      @include center; } }
  &__nav {
    margin-right: 200px;
    @include r(1040) {
      display: none; }
    &__list {
      display: flex;
      align-items: center;
      a {
        font-size: 16px;
        font-weight: normal;
        color: #3c4646;
        padding-bottom: 7px; }
      li {
        margin-right: 17px;
        position: relative;
        i {
          font-size: 10px;
          position: relative;
          top: 1px;
          @include tr;
          margin-left: 4px; }
        &:hover {
          i, a {
            color: $cherry-red; }
          .header__nav__dropdown {
            visibility: visible;
            opacity: 1;
            a {
              color: #3c4646; } } }
        &.active {
          a {
            border-bottom: 1px solid $cherry-red; } } } }
    &__dropdown {
      visibility: hidden;
      opacity: 0;
      @include tr;
      position: absolute;
      left: 0;
      top: 30px;
      background-color: #f9f9f9;
      padding: 15px 10px 5px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 620px;
      max-height: 290px;
      li {
        margin-right: 25px; }
      a {
        margin-bottom: 7px;
        display: block;
        width: 180px;
        &:hover {
          color: $cherry-red !important; } }
      &-secondary {
        width: 125px;
        height: auto; } } }
  &__connection {
    position: relative;
    display: flex;
    a {
      color: $gunmetal;
      font-size: 18px;
      margin-right: 20px;
      @include r(767) {
        font-size: 22px;
        margin-right: 30px; }
      &:hover {
        color: $cherry-red; } }
    &__search {
      width: 20px;
      overflow: hidden;
      &.is-active {
        a {
          display: block; }
        button {
          left: 0; } }
      div {
        width: 0px;
        position: absolute;
        background: $white;
        right: 0;
        top: -10px;
        padding: 10px 0;
        border-bottom: 1px solid $very-light-pink-three;
        @include center;
        @include tr;
        &.is-active {
          width: 340px;
          @include r(1100) {
            width: 300px; }
          @include r(767) {
            width: calc(100vw - 90px); } }
        input {
          width: 100%;
          font-weight: normal;
          color: $charcoal-grey;
          @include placeholder-color($light-grey-blue);
          margin-left: 18px;
          border-bottom: none;
          padding: 0; }
        button {
          background: none;
          display: block;
          position: relative;
          @include tr;
          &:hover {
            i {
              color: $cherry-red; } }
          i {
            font-size: 18px;
            text-align: center;
            font-weight: 900;
            color: $gunmetal;
            @include tr;
            @include r(767) {
              font-size: 22px; } } }
        a {
          color: $light-grey-blue;
          display: none;
          margin-right: 0;
          i {
            font-size: 13px;
            @include r(767) {
              font-size: 15px; } } } } } } }
