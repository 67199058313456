.team {
  overflow: hidden;
  &__content {
    padding: 50px 0; }
  .tabs__caption {
    margin-bottom: 35px;
    @include r(767) {
      display: flex;
      justify-content: space-around; }
    li {
      cursor: pointer;
      margin-right: 30px;
      @include r(767) {
        margin-right: 0; } }
    .active {
      background: $cherry-red;
      color: $white;
      cursor: default; } }
  .tabs__content {
    display: none;
    &.active {
      display: block; } }
  &__block {
    padding: 30px 0;
    border-bottom: 1px solid $very-light-pink;
    @include center;
    @include r(767) {
      flex-direction: column; }
    &:last-child {
      border-bottom: none; }
    &__img {
      border: solid 1px $light-grey-blue;
      width: 225px;
      height: 225px;
      @include r(767) {
        margin-bottom: 30px; }
      img {
        width: 100%;
        height: 100%;
        @include object-fit(cover); } }
    &__text {
      flex: 1;
      margin-left: 20px;
      @include r(767) {
        margin-left: 0; }
      h4 {
        font-weight: 600;
        line-height: normal;
        font-size: 16px;
        color: $gunmetal;
        text-transform: uppercase;
        @include r(767) {
          font-size: 20px; } }
      b {
        font-size: 14px;
        line-height: 1.71;
        color: $gunmetal;
        font-weight: 600;
        @include r(767) {
          font-size: 18px;
          display: block; } }
      p {
        line-height: 1.38;
        color: $gunmetal;
        @include r(767) {
          text-align: justify; } }
      &__link {
        @include center;
        justify-content: flex-start;
        padding-top: 5px;
        @include r(767) {
          flex-wrap: wrap; }
        span {
          color: $gunmetal;
          font-size: 14px;
          margin-right: 3px;
          position: relative;
          @include r(767) {
            font-size: 18px; }
          &:before {
            content: '|';
            margin: 0 8px;
            color: $gunmetal;
            font-size: 14px;
            @include r(767) {
              display: none; } } }
        a {
          font-size: 14px;
          line-height: 1.71;
          color: #ff0c11;
          @include r(767) {
            font-size: 18px; }
          &:first-child {
            width: 100%; } } } } } }
