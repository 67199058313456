.home-offer {
  overflow: hidden;
  .swiper-button-prev, .swiper-button-next {
    display: flex; }
  h2 {
    padding: 50px 0;
    @include r(767) {
      padding: 0 0 35px; } }
  &__content {
    display: flex;
    flex-wrap: wrap; }
  &__block {
    width: 25%;
    height: 175px;
    display: block;
    position: relative;
    @include r(1100) {
      width: 50%; }
    @include r(767) {
      width: 100%; }
    img {
      height: 100%;
      width: 100%;
      @include object-fit(cover); }
    span {
      width: 100%;
      height: 100%;
      @include center;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0 ,0, 0.5);
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: $white;
      text-transform: uppercase;
      padding: 0 60px;
      z-index: 3;
      box-sizing: border-box;
      @include tr;
      &:hover {
        background: rgba(180, 100, 120, 0.7) !important; }
      @include r(1600) {
        padding: 0 30px; }
      @include r(1300) {
        padding: 0 15px; }
      @include r(767) {
        padding: 0 60px; } }
    &:hover {
      span {
        background: rgba(0, 0 ,0, 0.5); } } } }
